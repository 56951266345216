<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>상담설정관리</h1>
            <div class="is-right">
            </div>
        </div>
        <!--첫번째 Row-->
        <div class="jh-cols is-space-20">
            <!--고객접수 설정-->
            <div class="jh-card">
                <div class="jh-card-header">
                    <h1>고객접수 설정</h1>
                    <div class="is-right">
                        <v-btn class="jh-btn is-main" id="btnSaveCard1" @click="saveCard(1)" v-if="this.mixin_set_btn(this.$options.name, 'btnSave1')">저장</v-btn>
                    </div>
                </div>
                <div class="jh-card-body">
                    <dl class="jh-list-form">
                        <dt><label>고객접수 설정</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card1.ROUTE_CD.CD">
                                <v-radio name="ROUTE_CD" label="고객접수시작" value="START"/>
                                <v-radio name="ROUTE_CD" label="고객접수중지" value="STOP"/>
                            </v-radio-group>              
                        </dd>
                        <dd><p class="jh-info-txt is-fill">고객 접수건을 상담사에게 배정하는 설정입니다.</p></dd>

                        <dt><label>채팅배정 제한(건)</label></dt>
                        <dd class="is-block">
                            <div class="jh-cols" style="width: 150px;">
                                <v-text-field type="number" class="jh-form" name="ROUTE_WAITING_CNT" v-model="card1.ROUTE_WAITING_CNT.CD" value="200" min="0" max="9999"></v-text-field>
                                <span class="jh-unit">건 이상</span>
                            </div>
                        </dd>
                        <dd><p class="jh-info-txt is-fill">고객 접수건이 설정된 건수 이상이 되면, 더이상 신규접수를 받지 않게 됩니다.</p></dd>

                        <!--
                        <dt><label>이미지 수신여부</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card1.RECEIVE_IMAGE_YN.CD">
                                <v-radio name="RECEIVE_IMAGE_YN" label="사용" value="Y"/>
                                <v-radio name="RECEIVE_IMAGE_YN" label="미사용" value="N"/>
                            </v-radio-group>              
                        </dd>
                        <dd><p class="jh-info-txt is-fill">고객이 전송한 이미지 수신여부를 설정합니다.</p></dd>
                        
                        <dt><label>장문 수신여부</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card1.RECEIVE_LONG_TXT_YN.CD">
                                <v-radio name="RECEIVE_LONG_TXT_YN" label="사용" value="Y"/>
                                <v-radio name="RECEIVE_LONG_TXT_YN" label="미사용" value="N"/>
                            </v-radio-group>             
                        </dd>
                        <dd><p class="jh-info-txt is-fill">고객이 전송한 장문 수신여부를 설정합니다.</p></dd>
                        -->
                    </dl>
                </div>
            </div>
            <!--//고객접수 설정-->

            <!--상담 설정-->
            <div class="jh-card">
                <div class="jh-card-header">
                    <h1>부서별 상담 설정</h1>
                    <div class="is-right">
                        <!--
                        <label class="jh-label">부서선택</label>
                        <v-select class="jh-form" style="width: 90px;" :items="items"></v-select>
                        -->
                        <v-btn class="jh-btn is-main" id="btnSaveCard2" @click="saveCard(2)" v-if="this.mixin_set_btn(this.$options.name, 'btnSave2')">저장</v-btn>
                    </div>
                    
                </div>
                <div class="jh-card-body">
                    <dl class="jh-list-form">
                        <dt><label>국내주식 상담가능 시간</label></dt>
                        <dd class="is-block">
                            <div class="jh-cols" style="width: 170px;">
                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                                    ref="menu3" v-model="menu3">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="jh-form" v-bind="attrs" v-on="on" append-icon="jh-icon-clock is-blue" 
                                        name="WORK_START_TIME_OVER_KT" v-model="card2.WORK_START_TIME_OVER_KT.CD"></v-text-field>
                                    </template>
                                    <v-time-picker class="jh-calendar" format="24hr"
                                        v-model="card2.WORK_START_TIME_OVER_KT.CD"
                                        :allowed-minutes="allowedStep"
                                        @click:minute="$refs.menu3.save()"
                                    ></v-time-picker>
                                </v-menu>
                                <span class="jh-unit">~</span>
                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                                    ref="menu4" v-model="menu4">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="jh-form" v-bind="attrs" v-on="on" append-icon="jh-icon-clock is-blue" 
                                        name="WORK_END_TIME_OVER_KT" v-model="card2.WORK_END_TIME_OVER_KT.CD"></v-text-field>
                                    </template>
                                    <v-time-picker class="jh-calendar" format="24hr"
                                        v-model="card2.WORK_END_TIME_OVER_KT.CD"
                                        :allowed-minutes="allowedStep"
                                        @click:minute="$refs.menu4.save()"
                                    ></v-time-picker>
                                </v-menu>
                            </div>                                          
                        </dd>
                        <dt><label>해외주식 상담가능 시간</label></dt>
                        <dd class="is-block">
                            <div class="jh-cols" style="width: 170px;">
                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                                    ref="menu5" v-model="menu5">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="jh-form" v-bind="attrs" v-on="on" append-icon="jh-icon-clock is-blue" 
                                        name="WORK_START_TIME_OVER_ST" v-model="card2.WORK_START_TIME_OVER_ST.CD"></v-text-field>
                                    </template>
                                    <v-time-picker class="jh-calendar" format="24hr"
                                        v-model="card2.WORK_START_TIME_OVER_ST.CD"
                                        :allowed-minutes="allowedStep"
                                        @click:minute="$refs.menu5.save()"
                                    ></v-time-picker>
                                </v-menu>
                                <span class="jh-unit">~</span>
                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                                    ref="menu6" v-model="menu6">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="jh-form" v-bind="attrs" v-on="on" append-icon="jh-icon-clock is-blue" 
                                        name="WORK_END_TIME_OVER_ST" v-model="card2.WORK_END_TIME_OVER_ST.CD"></v-text-field>
                                    </template>
                                    <v-time-picker class="jh-calendar" format="24hr"
                                        v-model="card2.WORK_END_TIME_OVER_ST.CD"
                                        :allowed-minutes="allowedStep"
                                        @click:minute="$refs.menu6.save()"
                                    ></v-time-picker>
                                </v-menu>
                            </div>                                          
                        </dd>
                        <dt><label>해외선물 상담가능 시간</label></dt>
                        <dd class="is-block">
                            <div class="jh-cols" style="width: 170px;">
                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                                    ref="menu7" v-model="menu7">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="jh-form" v-bind="attrs" v-on="on" append-icon="jh-icon-clock is-blue" 
                                        name="WORK_START_TIME_OVER_FT" v-model="card2.WORK_START_TIME_OVER_FT.CD"></v-text-field>
                                    </template>
                                    <v-time-picker class="jh-calendar" format="24hr"
                                        v-model="card2.WORK_START_TIME_OVER_FT.CD"
                                        :allowed-minutes="allowedStep"
                                        @click:minute="$refs.menu7.save()"
                                    ></v-time-picker>
                                </v-menu>
                                <span class="jh-unit">~</span>
                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                                    ref="menu8" v-model="menu8">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="jh-form" v-bind="attrs" v-on="on" append-icon="jh-icon-clock is-blue" 
                                        name="WORK_END_TIME_OVER_FT" v-model="card2.WORK_END_TIME_OVER_FT.CD"></v-text-field>
                                    </template>
                                    <v-time-picker class="jh-calendar" format="24hr"
                                        v-model="card2.WORK_END_TIME_OVER_FT.CD"
                                        :allowed-minutes="allowedStep"
                                        @click:minute="$refs.menu8.save()"
                                    ></v-time-picker>
                                </v-menu>
                            </div>                                          
                        </dd>
                        <dd><p class="jh-info-txt is-fill">상담이 가능한 시간을 설정합니다.</p></dd>
                        <!--
                        <dt><label>점심시간 사용여부 및 시간</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card2.LUNCH_YN.CD">
                                <v-radio class="is-mr-5" name="LUNCH_YN" label="사용" value="Y"/>
                                <div class="jh-cols is-mr-20" style="width: 170px;">
                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                                        ref="menu1" v-model="menu1" id="lunchStart">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="jh-form" v-bind="attrs" v-on="on" append-icon="jh-icon-clock is-blue" 
                                            name="LUNCH_START_TIME" v-model="card2.LUNCH_START_TIME.CD" :disabled="card2.LUNCH_YN.CD === 'N'"></v-text-field>
                                        </template>
                                        <v-time-picker class="jh-calendar" format="24hr"
                                            v-model="card2.LUNCH_START_TIME.CD"
                                            :allowed-minutes="allowedStep"
                                            @click:minute="$refs.menu1.save()"
                                        ></v-time-picker>
                                    </v-menu>
                                    <span class="jh-unit">~</span>
                                    <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                                        ref="menu2" v-model="menu2" id="lunchEnd">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field class="jh-form" v-bind="attrs" v-on="on" append-icon="jh-icon-clock is-blue" 
                                            name="LUNCH_END_TIME" v-model="card2.LUNCH_END_TIME.CD" :disabled="card2.LUNCH_YN.CD === 'N'"></v-text-field>
                                        </template>
                                        <v-time-picker class="jh-calendar" format="24hr"
                                            v-model="card2.LUNCH_END_TIME.CD"
                                            :allowed-minutes="allowedStep"
                                            @click:minute="$refs.menu2.save()"
                                        ></v-time-picker>
                                    </v-menu>
                                </div>
                                <v-radio name="LUNCH_YN" label="미사용" value="N"/>
                              </v-radio-group>                
                        </dd>
                        <dd><p class="jh-info-txt is-fill">점심시간으로 설정된 시간동안에는 접수를 받지 않습니다.</p></dd>
                        -->

                        <dt><label>상담사 자동인사 사용여부</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card2.AUTO_GREETING_YN.CD">
                                <v-radio name="AUTO_GREETING_YN" label="사용" value="Y"/>
                                <v-radio name="AUTO_GREETING_YN" label="미사용" value="N"/>
                            </v-radio-group>
                        </dd>
                        <dd><p class="jh-info-txt is-fill">채팅이 시작되면 자동인사 메시지가 고객에게 발송됩니다.</p></dd>

                        <dt><label>상담허용수 일괄적용</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card2.CONT_CHATAGENT_YN.CD">
                                <v-radio class="is-mr-5" name="CONT_CHATAGENT_YN" label="사용" value="Y"/>
                                <v-text-field type="number" class="jh-form is-col-fix is-mr-20" style="width: 70px;" name="CONT_CHATAGENT_CNT" v-model="card2.CONT_CHATAGENT_CNT.CD" :disabled="card2.CONT_CHATAGENT_YN.CD === 'N'" value="200" min="0" max="9999"></v-text-field>
                                <v-radio name="CONT_CHATAGENT_YN" label="미사용" value="N"/>
                            </v-radio-group>            
                        </dd>
                        <dd><p class="jh-info-txt is-fill">관리자가 채팅허용수를 지정할 지를 설정합니다.</p></dd>

                        <dt><label>제3자 채팅시작 알림</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card2.CONSULT_ALRAM_YN.CD">
                                <v-radio name="CONSULT_ALRAM_YN" label="사용" value="Y"/>
                                <v-radio name="CONSULT_ALRAM_YN" label="미사용" value="N"/>
                            </v-radio-group>
                        </dd>
                        <dd><p class="jh-info-txt is-fill">제3자 채팅 시작/종료 알림여부 설정합니다.</p></dd>
                    </dl>
                </div>
            </div>
            <!--//상담 설정-->

            <!--배분 설정-->
            <div class="jh-card">
                <div class="jh-card-header">
                    <h1>배분 설정</h1>
                    <div class="is-right">
                        <v-btn class="jh-btn is-main" id="btnSaveCard3" @click="saveCard(3)" v-if="this.mixin_set_btn(this.$options.name, 'btnSave3')">저장</v-btn>
                    </div>
                </div>
                <div class="jh-card-body">
                    <dl class="jh-list-form">
                        <dt><label>배분 선택</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card3.SPEC_CNSL_ROUTE.CD">
                                <v-radio name="SPEC_CNSL_ROUTE" label="자동배분" value="NORMAL"/>
                                <v-radio name="SPEC_CNSL_ROUTE" label="전문상담만배분" value="ONLY_SPEC"/>
                            </v-radio-group>
                        </dd>
                        <dd>
                            <p class="jh-info-txt is-fill"><b>자동배분 :</b> 인입된 고객 접수건을 자동으로 상담사에게 배분합니다.</p>
                            <p class="jh-info-txt is-fill"><b>전문상담만배분 :</b> 고객이 선택한 문의유형에 해당하는 전문상담사에게 배분합니다.</p>
                        </dd>

                        <dt><label>직전상담사 배분 사용여부</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card3.BEFORE_AGENT_USE_YN.CD">
                                <v-radio name="BEFORE_AGENT_USE_YN" label="사용" value="Y"/>
                                <v-radio name="BEFORE_AGENT_USE_YN" label="미사용" value="N"/>
                            </v-radio-group>
                        </dd>
                        <dd><p class="jh-info-txt is-fill">직전에 상담한 이력이 있으면, 해당 상담사에게 배분합니다.</p></dd>
                        
                        <dt><label>직전상담사 배분 제한기간</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card3.BEFORE_AGENT_PERIOD.CD">
                                <v-radio name="BEFORE_AGENT_PERIOD" label="1일" value="1"/>
                                <v-radio name="BEFORE_AGENT_PERIOD" label="3일" value="3"/>
                                <v-radio name="BEFORE_AGENT_PERIOD" label="없음" value="0"/>
                            </v-radio-group>
                        </dd>
                        <dd><p class="jh-info-txt is-fill">직전에 상담한 이력이 있으면, 해당 상담사에게 배분합니다.</p></dd>
                        
                        <!-- <dt><label>지정상담사 배분 고객 문의유형 사용여부</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card3.FIXED_ROUTE_INQRY_USE_YN.CD">
                                <v-radio name="FIXED_ROUTE_INQRY_USE_YN" label="사용" value="Y"/>
                                <v-radio name="FIXED_ROUTE_INQRY_USE_YN" label="미사용" value="N"/>
                            </v-radio-group>
                        </dd>
                        <dd><p class="jh-info-txt is-fill">지정상담사 인입 시 고객 문의유형을 사용할지 여부 설정합니다.</p></dd> -->
                    </dl>
                </div>
            </div>
            <!--//배분 설정-->

            <!--문의유형 설정-->
            <!--
            <div class="jh-card">
                <div class="jh-card-header">
                    <h1>문의유형 설정</h1>
                    <div class="is-right">
                        <v-btn class="jh-btn is-main" id="btnSaveCard4" @click="saveCard(4)" v-if="this.mixin_set_btn(this.$options.name, 'btnSave4')">저장</v-btn>
                    </div>
                </div>
                <div class="jh-card-body">
                    <dl class="jh-list-form">
                        <dt><label>고객 문의유형</label></dt>
                        <dd class="is-block">
                            <v-radio-group class="jh-radio" row v-model="card4.INQRY_TYPE_YN.CD">
                                <v-radio name="INQRY_TYPE_YN" label="사용" value="Y"/>
                                <v-radio name="INQRY_TYPE_YN" label="미사용" value="N"/>
                            </v-radio-group>
                        </dd>
                        <dd><p class="jh-info-txt is-fill">고객 접수 시, 문의유형을 선택하도록 합니다.</p></dd>

                        <dt><label>문의유형 사용레벨</label></dt>
                        <dd class="is-block">
                            <div class="jh-cols" style="width: 90px;">
                                <v-text-field type="number" class="jh-form" name="INQRY_USE_LVL" v-model="card4.INQRY_USE_LVL.CD" value="1" min="1" max="4"></v-text-field>
                                <span class="jh-unit">레벨</span>
                            </div>
                        </dd>
                        <dd><p class="jh-info-txt is-fill">사용레벨만큼의 문의유형이 고객에게 발송됩니다.</p></dd>
                    </dl>
                </div>
            </div>
            -->
            <!--//문의유형 설정-->
            <!--무응답 설정-->
            <div class="jh-card">
              <div class="jh-card-header">
                  <h1>무응답 설정</h1>
                  <div class="is-right">
                      <v-btn class="jh-btn is-main" id="btnSaveCard5" @click="saveCard(5)" v-if="this.mixin_set_btn(this.$options.name, 'btnSave5')">저장</v-btn>
                  </div>
              </div>
              <div class="jh-card-body">
                  <dl class="jh-list-form">
                      <dt><label>고객무응답 사용여부</label></dt>
                      <dd class="is-block">
                          <v-radio-group class="jh-radio" row v-model="card5.CUST_NORESP_USE_YN.CD">
                              <v-radio name="CUST_NORESP_USE_YN" label="사용" value="Y"/>
                              <v-radio name="CUST_NORESP_USE_YN" label="미사용" value="N"/>
                          </v-radio-group>
                      </dd>
                      <dd><p class="jh-info-txt is-fill">고객이 무응답 시, 무응답 메시지를 고객에게 발송합니다.</p></dd>

                      <dt><label>고객무응답 후 종료</label></dt>
                      <dd class="is-block">
                          <v-radio-group class="jh-radio" row v-model="card5.CUST_NORESP_CHATEND.CD">
                              <v-radio name="CUST_NORESP_CHATEND" label="사용" value="Y"/>
                              <v-radio name="CUST_NORESP_CHATEND" label="미사용" value="N"/>
                          </v-radio-group>
                      </dd>
                      <dd><p class="jh-info-txt is-fill">마지막 무응답 메시지 발송된 후, 자동으로 채팅을 종료할지에 대한 여부를 설정합니다.</p></dd>
                  </dl>
              </div>
          </div>
          <!--//무응답 설정-->
            <!--기타 설정-->
            <div class="jh-card">
              <div class="jh-card-header">
                  <h1>기타 설정</h1>
                  <div class="is-right">
                      <v-btn class="jh-btn is-main" id="btnSaveCard6" @click="saveCard(6)" v-if="this.mixin_set_btn(this.$options.name, 'btnSave6')">저장</v-btn>
                  </div>
              </div>
              <div class="jh-card-body">
                  <dl class="jh-list-form">
                      <dt><label>금칙어 적용여부</label></dt>
                      <dd class="is-block">
                          <v-radio-group class="jh-radio" row v-model="card6.PROHIBITE_APPLY_YN.CD">
                              <v-radio name="PROHIBITE_APPLY_YN" label="사용" value="APPLY"/>
                              <v-radio name="PROHIBITE_APPLY_YN" label="미사용" value="NOTAPPLY"/>
                          </v-radio-group>
                      </dd>
                      <dd><p class="jh-info-txt is-fill">채팅 내용 중, 금칙어가 전환어로 변환되어 보여집니다.</p></dd>

                      <dt><label>자동응답메시지 사용여부</label></dt>
                      <dd class="is-block">
                          <v-radio-group class="jh-radio" row v-model="card6.AUTO_MESSAGE_YN.CD">
                              <v-radio name="AUTO_MESSAGE_YN" label="사용" value="Y"/>
                              <v-radio name="AUTO_MESSAGE_YN" label="미사용" value="N"/>
                          </v-radio-group>
                      </dd>
                      <dd><p class="jh-info-txt is-fill">업무시간 외, 휴일에 자동응답메시지가 발송됩니다.</p></dd>
                  </dl>
              </div>
          </div>
          <!--//기타 설정-->
        </div>
        <!--//첫번째 Row-->

        <!--두번째 Row-->
        <div class="jh-cols is-space-20 is-pb-30">
            <div></div>
            <div></div>
        </div>
        <!--//두번째 Row-->
    </div>
</template>

<script>
import DialogM810401P01 from "./old/M810401P01.vue";
import {mixin} from "../../mixin/mixin";
import api from "../../store/apiUtil";

export default {
  name: "MENU_E020605", //name은 'MENU_' + 파일명 조합
  components: {
    DialogM810401P01,
  },
  mixins:[mixin],
  data() {
    return {
      isMainFrame: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
      ],
      aspDataDropItem: [],

      aspNewcustKey: '1040',
      card1: {
        ROUTE_CD: {CD: '', NM: ''},
        ROUTE_WAITING_CNT: {CD: '', NM: ''},
        RECEIVE_IMAGE_YN: {CD: '', NM: ''},
        RECEIVE_LONG_TXT_YN: {CD: '', NM: ''},
      },
      card2: {
        WORK_START_TIME_OVER_KT: {CD: '', NM: ''},
        WORK_END_TIME_OVER_KT: {CD: '', NM: ''},
        WORK_START_TIME_OVER_ST: {CD: '', NM: ''},
        WORK_END_TIME_OVER_ST: {CD: '', NM: ''},
        WORK_START_TIME_OVER_FT: {CD: '', NM: ''},
        WORK_END_TIME_OVER_FT: {CD: '', NM: ''},
        LUNCH_YN: {CD: '', NM: ''},
        LUNCH_START_TIME: {CD: '', NM: ''},
        LUNCH_END_TIME: {CD: '', NM: ''},
        AUTO_GREETING_YN: {CD: '', NM: ''},
        CONT_CHATAGENT_YN: {CD: '', NM: ''},
        CONT_CHATAGENT_CNT: {CD: '', NM: ''},
        CONSULT_ALRAM_YN: {CD: '', NM: ''},
      },
      card3: {
        SPEC_CNSL_ROUTE: {CD: '', NM: ''},
        BEFORE_AGENT_USE_YN: {CD: '', NM: ''},
        BEFORE_AGENT_PERIOD: {CD: '', NM: ''},
        FIXED_ROUTE_INQRY_USE_YN: {CD: '', NM: ''},
      },
      card4: {
        INQRY_TYPE_YN: {CD: '', NM: ''},
        INQRY_USE_LVL: {CD: '', NM: ''},
      },
      card5: {
        CUST_NORESP_USE_YN: {CD: '', NM: ''},
        CUST_NORESP_CHATEND: {CD: '', NM: ''},
      },
      card6: {
        PROHIBITE_APPLY_YN: {CD: '', NM: ''},
        AUTO_MESSAGE_YN: {CD: '', NM: ''},
      },
    }
  },
  methods: {
    init(){
      this.aspDataDropItem = this.$store.getters['userStore/GE_USER_COMPANY'];
      //console.log(`*********** aspDataDropItem : ${JSON.stringify(this.aspDataDropItem)}`)
      if(this.aspDataDropItem.length > 0){
        this.aspNewcustKey =  this.aspDataDropItem[0].CD
        this.getList()
      }
    },
    //aspNewcustKey
    async getList(){
      if( this.mixin_isEmpty(this.aspNewcustKey) ){
        // this.common_alert("회사를 선택해 주세요.", "error");
        // this.isMainFrame = false;
        return;
      }

      let requestData = {
        headers: {},
        sendData:{}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/manage/inqire";
      requestData.headers["SERVICE"] = "chat.setting.manage";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;

      let res = await this.common_postCall(requestData);
      this.getListCallBack(res);
    },
    getListCallBack(response){
      let data = response.DATA;
      // 한건도 없을 경우 초기화
      this.card1 = JSON.parse(JSON.stringify(this.defaultVO.card1));
      this.card2 = JSON.parse(JSON.stringify(this.defaultVO.card2));
      this.card3 = JSON.parse(JSON.stringify(this.defaultVO.card3));
      this.card4 = JSON.parse(JSON.stringify(this.defaultVO.card4));
      this.card5 = JSON.parse(JSON.stringify(this.defaultVO.card5));
      this.card6 = JSON.parse(JSON.stringify(this.defaultVO.card6));

      this.isMainFrame = true;
      let intCnt = data.length;

      for(let i=0; i<intCnt; i++) {
        let strEnvCd = data[i].ENV_CD;				//설정코드값
        let strEnvCdNm = data[i].ENV_CD_NM;				//설정코드값
        let strEnvSetVal = data[i].ENV_SET_VALUE;	//설정값

        // card1
        let cardData = this.card1;
        for(let prop in cardData){
          if(prop === strEnvCd){
            cardData[prop].CD = strEnvSetVal;
            cardData[prop].NM = strEnvCdNm;
          }
        }

        //card2
        cardData = this.card2;
        for(let prop in cardData){
          if( prop === strEnvCd ){
            if( prop === "WORK_START_TIME_OVER_KT" || prop === "WORK_END_TIME_OVER_KT" || prop === "WORK_START_TIME_OVER_ST" || prop === "WORK_END_TIME_OVER_ST" || prop === "WORK_START_TIME_OVER_FT" || prop === "WORK_END_TIME_OVER_FT" || prop === "LUNCH_START_TIME" || prop === "LUNCH_END_TIME" ){
              // 시간 포맷 변경필요.
              cardData[prop].CD = strEnvSetVal.substr(0,2) + ":" + strEnvSetVal.substr(2,2);
            }else{
              cardData[prop].CD = strEnvSetVal;
            }
            cardData[prop].NM = strEnvCdNm;
          }
        }

        //card3
        cardData = this.card3;
        for(let prop in cardData){
          if(prop === strEnvCd){
            cardData[prop].CD = strEnvSetVal;
            cardData[prop].NM = strEnvCdNm;
          }
        }

        //card4
        cardData = this.card4;
        for(let prop in cardData){
          if(prop === strEnvCd){
            cardData[prop].CD = strEnvSetVal;
            cardData[prop].NM = strEnvCdNm;
          }
        }

        //card5
        cardData = this.card5;
        for(let prop in cardData){
          if(prop === strEnvCd){
            cardData[prop].CD = strEnvSetVal;
            cardData[prop].NM = strEnvCdNm;
          }
        }

        //card6
        cardData = this.card6;
        for(let prop in cardData){
          if(prop === strEnvCd){
            cardData[prop].CD = strEnvSetVal;
            cardData[prop].NM = strEnvCdNm;
          }
        }
      }


      // 한건도 없을 경우 초기화 진행 후 종료
      if(data.length < 1) {
        this.isMainFrame = true;
        this.card1 = JSON.parse(JSON.stringify(this.defaultVO.card1));
        this.card2 = JSON.parse(JSON.stringify(this.defaultVO.card2));
        this.card3 = JSON.parse(JSON.stringify(this.defaultVO.card3));
        this.card4 = JSON.parse(JSON.stringify(this.defaultVO.card4));
        this.card5 = JSON.parse(JSON.stringify(this.defaultVO.card5));
        this.card6 = JSON.parse(JSON.stringify(this.defaultVO.card6));
        return;
      }

      for( let prop in this.card1 ){
        if(this.mixin_isEmpty(this.card1[prop].CD)){
          this.card1 = JSON.parse(JSON.stringify(this.defaultVO.card1));
          break;
        }
      }
      for( let prop in this.card2 ){
        if(this.mixin_isEmpty(this.card2[prop].CD)){
          this.card2 = JSON.parse(JSON.stringify(this.defaultVO.card2));
          break;
        }
      }
      for( let prop in this.card3 ){
        if(this.mixin_isEmpty(this.card3[prop].CD)){
          this.card3 = JSON.parse(JSON.stringify(this.defaultVO.card3));
          break;
        }
      }
      for( let prop in this.card4 ){
        if(this.mixin_isEmpty(this.card4[prop].CD)){
          this.card4 = JSON.parse(JSON.stringify(this.defaultVO.card4));
          break;
        }
      }
      for( let prop in this.card5 ){
        if(this.mixin_isEmpty(this.card5[prop].CD)){
          this.card5 = JSON.parse(JSON.stringify(this.defaultVO.card5));
          break;
        }
      }
      for( let prop in this.card6 ){
        if(this.mixin_isEmpty(this.card6[prop].CD)){
          this.card6 = JSON.parse(JSON.stringify(this.defaultVO.card6));
          break;
        }
      }
    },
    saveCard(num){
      let msg = "";
      if(num === 1){
        msg = "고객접수 설정 ";
      }else if(num === 2){
        msg = "상담 설정 ";
      }else if(num === 3){
        msg = "배분 설정 ";
      }else if(num === 4){
        msg = "문의유형 설정 ";
      }else if(num === 5){
        msg = "무응답 설정 ";
      }else if(num === 6){
        msg = "기타 설정 ";
      }
      msg += "수정하시겠습니까?";
      this.common_confirm(msg, async () => {
        await this.saveCardYesCall(num);
       }, null, null, null, "chk");
    },
    saveCardYesCall(num){
      let requestData = {
        headers: {},
        sendData:{}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/chat/setting/manage/modify";
      requestData.headers["SERVICE"] = "chat.setting.manage";
      requestData.headers["METHOD"] = "modify";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      let sendDataList = [];

      // card 넘버로 데이터 구분하여 바로 처리.
      let cardData = eval("this.card" + num);
      //let cardData = this.card1;
      let cnt = 0;
      for( let prop in cardData ){
        let obj = {};
        if(cnt == 0){
          obj["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
          obj["ASP_CUST_KEY"] = this.aspNewcustKey;
          obj["PROC_ID"]= this.$store.getters['userStore/GE_USER_ROLE'].userId;
          obj["ASP_USER_ID"]= this.$store.getters['userStore/GE_USER_ROLE'].userId;     
        }
        obj["ENV_CD"]= prop;
        obj["ENV_CD_NM"]= cardData[prop].NM;
        if(num === 2){
          obj["ASP_NEWCUST_KEY"] = this.aspNewcustKey;
          obj["ASP_CUST_KEY"] = this.aspNewcustKey;
          obj["ASP_USER_ID"]= this.$store.getters['userStore/GE_USER_ROLE'].userId;
          if( prop === "WORK_START_TIME_OVER_KT" || prop === "WORK_END_TIME_OVER_KT" || prop === "WORK_START_TIME_OVER_ST" || prop === "WORK_END_TIME_OVER_ST" || prop === "WORK_START_TIME_OVER_FT" || prop === "WORK_END_TIME_OVER_FT" || prop === "LUNCH_START_TIME" || prop === "LUNCH_END_TIME" ){
            obj["ENV_SET_VALUE"]= cardData[prop].CD.replace(":","");
          }else{
            obj["ENV_SET_VALUE"]= cardData[prop].CD;
          }
        }else{
          obj["ENV_SET_VALUE"]= cardData[prop].CD;
        }
        sendDataList.push(obj);
        cnt++;
      }
      requestData.sendData = {
        list : sendDataList
      };
      this.submitCall(requestData);
    },
    async submitCall(requestData){
      const res = await this.common_postCall(requestData);
      this.saveCardCallBack(res);
    },
    saveCardCallBack(res){
      if( this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
        return;
      }
      this.common_alert("정상적으로 수정되었습니다.");
    },
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    allowedStep: (m) => m % 10 === 0,
    showDialog(type) {
      this[`dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    submitDialog(type) {
      this[`dialog${type}`] = false;
    },
    resetDialog() {
      console.log("reset");
    },
    unlockDialog() {
      console.log("unlock");
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    defaultVO() {
      return {
        "card1": {
          "ROUTE_CD": {
            "CD": "START",
            "NM": "분배상태코드"
          },
          "ROUTE_WAITING_CNT": {
            "CD": "100",
            "NM": "분배대기인원"
          },
          "RECEIVE_IMAGE_YN": {
            "CD": "Y",
            "NM": "이미지 수신여부"
          },
          "RECEIVE_LONG_TXT_YN": {
            "CD": "Y",
            "NM": "장문 수신여부"
          }
        },
        "card2": {
          "WORK_START_TIME_OVER_KT": {
            "CD": "09:00",
            "NM": "주식상담시작시간"
          },
          "WORK_END_TIME_OVER_KT": {
            "CD": "18:00",
            "NM": "주식상담종료시간"
          },
          "WORK_START_TIME_OVER_ST": {
            "CD": "09:00",
            "NM": "해외주식상담시작시간"
          },
          "WORK_END_TIME_OVER_ST": {
            "CD": "18:00",
            "NM": "해외주식상담종료시간"
          },
          "WORK_START_TIME_OVER_FT": {
            "CD": "09:00",
            "NM": "해외선물상담시작시간"
          },
          "WORK_END_TIME_OVER_FT": {
            "CD": "18:00",
            "NM": "해외선물상담종료시간"
          },
          "WORK_START_TIME_OVER_KT": {
            "CD": "09:00",
            "NM": "상담시작시간"
          },
          "WORK_END_TIME_OVER_KT": {
            "CD": "18:00",
            "NM": "상담종료시간"
          },
          "LUNCH_YN": {
            "CD": "Y",
            "NM": "점심시간 사용여부"
          },
          "LUNCH_START_TIME": {
            "CD": "12:00",
            "NM": "점심시작시간"
          },
          "LUNCH_END_TIME": {
            "CD": "13:00",
            "NM": "점심종료시간"
          },
          "AUTO_GREETING_YN": {
            "CD": "Y",
            "NM": "상담사자동인사사용여부"
          },
          "CONT_CHATAGENT_YN": {
            "CD": "Y",
            "NM": "상담허용수일괄적용"
          },
          "CONT_CHATAGENT_CNT": {
            "CD": "10",
            "NM": "상담허용수일괄적용값"
          },
          "CONSULT_ALRAM_YN": {
            "CD": "Y",
            "NM": "제 3자채팅알림 여부"
          }
        },
        "card3": {
          "SPEC_CNSL_ROUTE": {
            "CD": "NORMAL",
            "NM": "전문상담만배분"
          },
          "BEFORE_AGENT_USE_YN": {
            "CD": "Y",
            "NM": "직전상담사 배분 사용여부"
          },
          "BEFORE_AGENT_PERIOD": {
            "CD": "1",
            "NM": "직전상담사 배분 기간  "
          },
          "FIXED_ROUTE_INQRY_USE_YN": {
            "CD": "Y",
            "NM": "지정상담사 배분 고객문의유형 사용여부 "
          }
        },
        "card4": {
          "INQRY_TYPE_YN": {
            "CD": "Y",
            "NM": "고객문의유형"
          },
          "INQRY_USE_LVL": {
            "CD": "4",
            "NM": "문의유형 사용레벨"
          }
        },
        "card5": {
          "CUST_NORESP_USE_YN": {
            "CD": "Y",
            "NM": "고객무응답메시지사용여부"
          },
          "CUST_NORESP_CHATEND": {
            "CD": "Y",
            "NM": "고객무응답메시지전송후채팅종료"
          }
        },
        "card6": {
          "PROHIBITE_APPLY_YN": {
            "CD": "APPLY",
            "NM": "금칙어사용여부"
          },
          "AUTO_MESSAGE_YN": {
            "CD": "Y",
            "NM": "자동응답메시지사용여부"
          }
        }
      }
    },
  },
  created() {
    this.init();
  }
};
</script>

<style></style>
